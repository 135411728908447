



























import { Component, Vue } from 'vue-property-decorator';
import ImportUiStateModule from '@/store/modules/ImportUiStateModule';

@Component
export default class ImportProcessingPage extends Vue {
  // at the moment we kick off the import from the processing page instead
  // of the file upload page as we also need to account for chrome imports.  This
  // might change if we improve the chrome extension
  async created() {
    ImportUiStateModule.startImport({
      uploadGuid: this.$route.params.uploadGuid,
      isChromeExtensionImport: this.$route.path.includes('/chrome/'),
    });
  }
}
